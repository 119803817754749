<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.ban-announce.comparison"></custom-page-title>

                <p>Az 'Egyeztetés' funkció az ország valamennyi
                    cégbíróságán vezetett cégjegyzék számlaszám adatainak, illetve a pénzintézetek
                    számlavezetésében fellelhető számlaszám adatoknak az adatharmonizációjához
                    szükséges adatokat közvetíti.</p>

                <p>Miután a pénzintézetek vezetik a cégjegyzékben a
                    számlaszámokat nyilvántartó rovatot, a cégbíróságoktól információt kérhetnek a
                    számlaszámok aktuális cégjegyzékadatairól (első alkalommal díjmentesen).</p>

                <p>Az Egyeztetés kérésére a bank írjon egy levelet
                    az <a
                        href="mailto:occsz@microsec.hu?Subject=Banksz%C3%A1mla%20egyeztet%C3%A9s%20k%C3%A9r%C3%A9se&amp;Body=Tisztelt%20Szolg%C3%A1ltat%C3%B3!%0D%0DK%C3%A9rem%20az%20XXX%20kezdet%C5%B1%20banksz%C3%A1mlasz%C3%A1mok%20kigy%C5%B1jt%C3%A9s%C3%A9t%20a%20c%C3%A9gjegyz%C3%A9kb%C5%91l.">occsz@microsec.hu</a> címre.
                    Adja meg a kért számlaszámok első 3-4 jegyét, mely a pénzintézetet azonosítja.</p>

                <p>A visszaadott TXT állomány egy-egy sora szóköz karakterekkel
                    elválasztva a következő adatokat tartalmazza:</p>

                <p style="text-align: center"><i>cégjegyzékszám, törzsszám, számlaszám, nyitás, zárás,
                    hattól, hatig.</i></p>

                <p>A hattól dátum a bejegyzés, a hatig dátum
                    pedig a törlés keltét mutatja a cégjegyzékben.</p>

                <p>A kapott adatokat a pénzintézetek összevethetik
                    számlavezetésükben fellelhető adatokkal, és amennyiben szükséges, a megfelelő
                    javításokat a szokásos jelentésben elküldhetik.</p>

                <p>Az adatharmonizáció során a vizsgálat eredményétől függően a
                    következő a teendő:</p>

                <v-simple-table>
                    <tbody>
                    <tr>
                        <td></td>
                        <td>
                            <p>A vizsgálat eredménye</p>
                        </td>
                        <td>
                            <p>Teendő</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>a</p>
                        </td>
                        <td>
                            <p>A cégbíróságon nyilvántartott számlaszám-adatok
                                megegyeznek a számlavezetés adataival.</p>
                        </td>
                        <td>
                            <p>nincs</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>b</p>
                        </td>
                        <td>
                            <p>A cégbíróságon nyilvántartott nyitott állapotú számla a
                                számlavezetésben zárt állapotú.</p>
                        </td>
                        <td>
                            <p>A számlaszámot 'Törlés' kóddal (a nyitás és zárás dátumát
                                feltüntetve) a cégjegyzékből töröltetni kell.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>c</p>
                        </td>
                        <td>
                            <p>A cégbíróságon nyilvántartott zárt állapotú számla a
                                számlavezetésben nyitott állapotú.</p>
                        </td>
                        <td>
                            <p>A számlaszám adatait 'Bejegyzés' kóddal a cégjegyzékbe be
                                kell jegyeztetni.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>d</p>
                        </td>
                        <td>
                            <p>A cégbíróságon nyilvántartott nyitott állapotú számla
                                nyitási dátuma különbözik a<br>
                                számlavezetésben található nyitási dátumtól.</p>
                        </td>
                        <td>
                            <p>A számlaszám nyitási dátumát 'Módosítás' kóddal a
                                cégjegyzékben módosíttatni kell.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>e</p>
                        </td>
                        <td>
                            <p>A cégbírósági egyeztető állományban nem található olyan
                                számlaszám, amely a számlavezetésben nyitott állapotban van.</p>
                        </td>
                        <td>
                            <p>A számlaszám adatait 'Bejegyzés' kóddal a cégjegyzékbe be
                                kell jegyeztetni.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>f</p>
                        </td>
                        <td>
                            <p>A cégbírósági egyeztető állományban nem található olyan
                                számlaszám, amely a számlavezetésben zárt állapotban van.</p>
                        </td>
                        <td>
                            <p>A számlaszámot 'Törlés' kóddal (a nyitás és zárás dátumát
                                feltüntetve) a cégjegyzékben törölt számlaszámnak
                                kell bejegyeztetni.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>g</p>
                        </td>
                        <td>
                            <p>A cégbírósági egyeztető állományban olyan számlaszám
                                szerepel, amely hibás számlaszám, vagy a számlavezetésben nem volt soha
                                nyilvántartva.</p>
                        </td>
                        <td>
                            <p>A számlaszámot 'HibásAdat'
                                kóddal a cégjegyzékből töröltetni kell.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>h</p>
                        </td>
                        <td>
                            <p>A cégbírósági egyeztető állományban olyan számlaszám
                                szerepel, amely a számlavezetésben más céghez tartozik.</p>
                        </td>
                        <td>
                            <p>Az egyeztető állományban szereplő cégmutató által kijelölt
                                számlaszámot 'HibásAdat' kóddal a cégjegyzékből
                                töröltetni kell.<br>
                                A számlavezetésben szereplő cég adataival a számlaszámot 'Bejegyzés' kóddal a
                                cégjegyzékbe be kell jegyeztetni.</p>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>

                <p>A <i>cégjegyzékszám,törzsszám,számlaszám</i> az
                    XML állományban egyedi, így a tételek sorrendiségének nincs szerepe.</p>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpBanAnnounceComparison extends Vue {
}
</script>

<style scoped>

</style>